// @ts-ignore
const modules = import.meta.glob('./modules/*.ts', {
  import: 'default',
  eager: true,
});
// console.log('modules:', modules)
let newModules: any = {}
for (let path in modules) {
  const name = path.split('/').pop().replace(/\.js|\.ts/, '')
  newModules[name] = modules[path]
}

// 所有主题名称, 第一个是没主题的时候的默认主题
// 开元28 themeKY28 themeKYMN
export const THEMES_NAME = ['themeKY28', 'themeKYMN'];
export const THEMES_LISTS = [{ text: '魅力红', value: 'themeKY28' }, { text: '明亮黄', value: 'themeKYMN' }];
export const { HIDE_THE_BOTTOM_PAGE } = newModules
